@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap');

body {
	height: 100%;	
	margin: 0;
  	font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Roboto';
  	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-image: radial-gradient( circle 1224px at 10.6% 8.8%,  rgba(255,255,255,1) 0%, rgba(153,202,251,1) 100.2% );
}
